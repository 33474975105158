<template>
  <div class="sign-review">
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
      @selection-change="handleSelectionChange"
    >
      <template slot="toolbar">
        <el-form-item label="审核状态">
          <el-select
            size="small"
            clearable
            v-model="advancedForm.status"
            placeholder="请选择审核状态"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input
            size="small"
            placeholder="企业名称"
            v-model="advancedForm.shop_name"
            clearable
          ></el-input>
        </el-form-item>
        <el-button
          @click="GET_GoodsAuditList"
          type="primary"
          size="small"
        >搜索</el-button>
        <div class="col"></div>
        <el-button
          @click="handleGoodsAudit(idsList, true)"
          size="small"
          type="primary"
        >批量审核</el-button>
      </template>

      <template slot="table-columns">
        <el-table-column
          type="selection"
          width="55"
        ></el-table-column>
        <!--商品图片-->
        <el-table-column
          prop="sign_id"
          label="编号"
          width="80"
        />
        <el-table-column
          label="企业营业执照"
          width="90"
          class-name="goods-cover-wrapper"
        >
          <template slot-scope="scope">
            <el-popover
              placement="right"
              trigger="hover"
            >
              <img
                :src="scope.row.licence_img"
                alt=""
                style="width: 300px"
              >
              <img
                :src="scope.row.licence_img"
                class="goods-cover"
                alt=""
                slot="reference"
              />
            </el-popover>
          </template>
        </el-table-column>
        <!--店铺名称-->
        <el-table-column
          prop="shop_name"
          label="企业名称"
        />
        <!--商品分类-->
        <el-table-column
          prop="sign_name"
          label="申请签名"
        />
        <el-table-column
          prop="status"
          label="状态"
          width="130"
        >
          <template slot-scope="scope">{{scope.row.status | statusFilter(that)}}</template>
        </el-table-column>
        <!--品牌名称-->
        <!--<el-table-column prop="brand_name" label="品牌名称"/>-->
        <!--操作-->
        <el-table-column
          label="操作"
          width="80"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status===1"
              size="mini"
              type="primary"
              @click="handleGoodsAudit([scope.row.sign_id])"
            >审核</el-button>
            <el-button
              v-else
              disabled
              size="mini"
              type="primary"
            >审核</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="MixinPageSizes"
        :page-size="tableData.page_size"
        :layout="MixinTableLayout"
        :total="tableData.data_total"
        background
      ></el-pagination>
    </en-table-layout>

    <!--审核 dialog-->
    <el-dialog-x-dialog
      :title="dialogGoodsAuditTitle"
      :visible.sync="dialogGoodsAuditVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="goodsAuditForm"
        :rules="goodsAuditRules"
        ref="goodsAuditForm"
        label-width="100px"
      >
        <!--是否通过=-->
        <el-form-item
          label="是否通过"
          prop="status"
        >
          <el-radio-group v-model="goodsAuditForm.status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">不通过</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="备注信息"
          prop="reason"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入审核备注(120字以内)"
            :maxlength="120"
            v-model="goodsAuditForm.reason"
          ></el-input>
        </el-form-item>
        <el-form-item style="text-align:right;">
          <el-button
            @click="dialogGoodsAuditVisible = false"
            size="small"
          >取 消</el-button>
          <el-button
            type="primary"
            @click="submitGoodsAuditForm"
            size="small"
          >确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
    <el-dialog
      title="查看商品变更"
      :visible.sync="dialogGoodspass"
      width="800px"
    >
      <el-table
        :data="tableDatapass"
        style="width: 100%"
      >
        <el-table-column
          prop="date"
          label="修改人"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="变更内容"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="变更时间"
          width="280"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import * as API_account from "@/api/account";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  name: "goodsAudit",
  components: { EnTableLayout },
  data() {
    return {
      that: this,
      idsList: [],
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
      },
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "审核中",
        },
        {
          value: 2,
          label: "平台审核拒绝",
        },
        {
          value: 3,
          label: "平台审核通过",
        },
        // {
        //   value: 4,
        //   label: "阿里云待审核",
        // },
        // {
        //   value: 5,
        //   label: "阿里云审核拒绝",
        // },
        {
          value: 6,
          label: "短信平台审核通过",
        },
      ],
      // 商品数据
      tableData: {},
      // 审核商品 表单
      goodsAuditForm: {
        reason: "",
        status: 1,
      },
      // 审核商品 表单校验
      goodsAuditRules: {
        message: [
          { required: false, message: "请输入审核备注！", trigger: "blur" },
        ],
      },
      // 审核商品 dialog
      dialogGoodsAuditVisible: false,
      // 审核商品 dialogTitle
      dialogGoodsAuditTitle: "审核",
      // 高级搜索数据
      advancedForm: {
        shop_name: "",
        status: "",
      },
      //查看商品变更记录
      dialogGoodspass: false,
      tableDatapass: [],
    };
  },
  filters: {
    statusFilter(val, that) {
      let obj = that.options.find((item) => {
        return item.value === val;
      })
      return obj ? obj['label'] : ''
    },
  },
  mounted() {
    this.GET_GoodsAuditList();
  },
  activated() {
    this.GET_GoodsAuditList();
  },
  watch: {
    "goodsAuditForm.status": function (newVal) {
      this.goodsAuditRules.message[0].required = newVal === 2;
    },
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },

    /** 审核商品 */
    handleGoodsAudit(ids, type) {
      this.dialogGoodsAuditTitle = type ? "批量审核" : "审核";
      if (0) {
        this.$router.push({
          name: "goodsAuditDetail",
          query: { sign_ids: ids[0], isdraft: 1 },
        });
      } else {
        console.log(ids, type);
        if (ids.length) {
          this.idsList = ids;
          this.goodsAuditForm = {
            sign_ids: ids,
            reason: "",
            status: 1,
          };
          this.dialogGoodsAuditVisible = true;
        } else {
          this.$message.error("请勾选商品");
        }
      }
    },

    /** 审核商品 表单提交 */
    submitGoodsAuditForm() {
      this.$refs["goodsAuditForm"].validate((valid) => {
        if (valid) {
          console.log(this.idsList, this.goodsAuditForm);
          API_account.getShopsSmsSignAuditSign(
            this.idsList,
            this.goodsAuditForm
          ).then((response) => {
            this.dialogGoodsAuditVisible = false;
            this.$message.success("审核完成！");
            this.GET_GoodsAuditList();
          });
        } else {
          this.$message.error("表单填写有误，请核对！");
          return false;
        }
      });
    },

    /** 获取待审核商品 */
    GET_GoodsAuditList() {
      this.loading = true;
      // //  getShopsSmsSignAuditSign
      let params = {
        ...this.params,
        ...this.advancedForm,
      };
      API_account.getShopsSmsSignList(params)
        .then((response) => {
          this.loading = false;
          if (this.tableData.data_total !== response.data_total){
            this.params.page_no = 1;
            this.tableData = response;
            this.GET_GoodsAuditList()
          }else this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.sign_id);
      });
      this.idsList = ids;
    },
  },
};
</script>
<style lang="scss">
.goods-cover-wrapper {
  img {
    width: 100px;
  }
}
</style>
